var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar",class:_vm.isSmallWindow ? 'cal-small' : 'calendar'},[_c('nav-month-row'),_c('edit-act-day',{attrs:{"date-events":_vm.calendarClickDay},on:{"event-updated":function($event){return _vm.getCalendar()}}}),_c('b-row',{staticClass:"row-cal-headers text-center font-weight-bold",attrs:{"no-gutters":"","align-v":"center"}},[_c('b-col',{staticClass:"cel-cal"},[_vm._v("Sunday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Monday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Tuesday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Wednesday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Thursday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Friday")]),_c('b-col',{staticClass:"cel-cal"},[_vm._v("Saturday")])],1),(!_vm.calendarDataLoading)?_c('div',_vm._l((_vm.calendarRows),function(row){return _c('b-row',{key:row,attrs:{"no-gutters":""}},_vm._l((_vm.calendarCols),function(col){return _c('b-col',{key:col},[(
            row * 7 + col + _vm.firsDayCalendarGrid > 0 &&
            row * 7 + col + _vm.firsDayCalendarGrid <= _vm.lastDayMonth
          )?_c('div',{class:{
            'cel-cal':
              row * 7 + col + _vm.firsDayCalendarGrid > 0 &&
              row * 7 + col + _vm.firsDayCalendarGrid <= _vm.lastDayMonth,
            'cell-col-0': col == 0,
            'cel-cal-today': _vm.isToday(row, col),
            'cel-cal-old': _vm.isOld(row, col),
          },on:{"click":function($event){return _vm.clickOnCell(row, col)}}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"text-left ml-1"},[_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.getFeast(row, col)))])]),_c('b-col',{staticClass:"text-right pr-1",class:[
                _vm.areThereNotes(row, col) ? 'date-with-notes' : 'text-muted' ]},[_vm._v(_vm._s(row * 7 + col + _vm.firsDayCalendarGrid))])],1),_vm._l((_vm.getMassesDay(row, col)),function(Mass){return _c('div',{key:Mass.Day + Mass.StartTime + Mass.Initials},[(_vm.dbName)?_c('div',{class:[
                Mass.ActInitials == '' ? 'cel-no-mass-info' : 'cel-mass-info' ]},[_vm._v(" "+_vm._s(Mass.ActInitials)+" "+_vm._s(Mass.Initials)+" "),(Mass.Initials=='')?_c('span',[_vm._v("No Mass")]):_vm._e()]):_vm._e()])}),_vm._l((_vm.getMyNetCalActivitiesDay(row, col)),function(Act){return _c('div',{key:Act.Day + Act.StartTime + Act.ActInitials + Act.Initials},[_c('div',{staticClass:"cal-badges-warning"},[_vm._v(_vm._s(_vm.getMyNetActFormat(Act)))])])}),_vm._l((_vm.getActivitiesDay(row, col)),function(Act){return _c('div',{key:Act.id},[_c('div',{staticClass:"cal-badges",attrs:{"variant":"danger"}},[(Act.Icon != '' && Act.Icon != 'arrow-right')?_c('span',[_c('font-awesome-icon',{staticClass:"mr-1 icons",attrs:{"icon":Act.Icon,"size":"lg"}})],1):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(Act.Act)}}),(Act.Icon == 'arrow-right')?_c('span',[_c('font-awesome-icon',{staticClass:"ml-1 icons",attrs:{"icon":Act.Icon,"size":"lg"}})],1):_vm._e()])])})],2):_c('div',{class:{
            'first-row-cells-blank': row * 7 + col + _vm.firsDayCalendarGrid <= 0,
            'last-row-cells-blank':
              row * 7 + col + _vm.firsDayCalendarGrid > _vm.lastDayMonth,
            'cell-day-0': row * 7 + col + _vm.firsDayCalendarGrid == 0,
          }},[_vm._v(" test ")])])}),1)}),1):_c('div',{staticClass:"mt-5"},[_c('font-awesome-icon',{style:({ color: 'blue' }),attrs:{"icon":"sync-alt","spin":"","size":"3x"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }