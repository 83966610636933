<style lang="scss" scoped>
.row-cal-headers {
  border-top: 1px solid #ccddee;
  border-left: 1px solid #ccddee;
  background-color: #0088dd;
  color: #ffffff;
}
.calendar {
  font-size: 0.8em;
}
.cal-small {
  font-size: 0.6em;
}
.cel-cal {
  border-right: 1px solid #ccddee;
  border-bottom: 1px solid #ccddee;
  height: 100%;
  cursor: pointer;
}
.first-row-cells-blank {
  border-bottom: 1px solid #ccddee;
  color: white;
  height: 100%;
}
.last-row-cells-blank {
  color: white;
}
.cell-day-0 {
  border-right: 1px solid #ccddee;
  height: 100%;
}
.cell-col-0 {
  border-left: 1px solid #ccddee;
  background-color: #f2f7fb;
}
.cel-mass-info {
  color: #0077ff;
}
.cel-no-mass-info {
  color: #ff2020;
}
.cal-badges {
  background-color: #56e6ff;
  border: 1px solid #fafafa;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
  color: #002222;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 0.85em;
  font-weight: 600;
  padding-right: 3px;
  padding-left: 3px;
}
.cal-badges-warning {
  background-color: #ffc107;
  border: 1px solid #fafafa;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
  color: #002222;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 0.85em;
  font-weight: 600;
  padding-right: 3px;
  padding-left: 3px;
}
.date-with-notes {
  color: #e00000;
  font-weight: 600;
}
.cel-cal-old {
  background-color: #6f6f6f1c;
}

.cel-cal-today {
  border: 1px solid green;
  background-color: #f0fff0;
}
.icons {
  color: #dc3545;
}
</style>

<template>
  <div class="calendar" v-bind:class="isSmallWindow ? 'cal-small' : 'calendar'">
    <nav-month-row />

    <edit-act-day
      v-bind:date-events="calendarClickDay"
      v-on:event-updated="getCalendar()"
    />

    <b-row
      class="row-cal-headers text-center font-weight-bold"
      no-gutters
      align-v="center"
    >
      <b-col class="cel-cal">Sunday</b-col>
      <b-col class="cel-cal">Monday</b-col>
      <b-col class="cel-cal">Tuesday</b-col>
      <b-col class="cel-cal">Wednesday</b-col>
      <b-col class="cel-cal">Thursday</b-col>
      <b-col class="cel-cal">Friday</b-col>
      <b-col class="cel-cal">Saturday</b-col>
    </b-row>

    <div v-if="!calendarDataLoading">
      <b-row v-for="row in calendarRows" v-bind:key="row" no-gutters>
        <b-col v-for="col in calendarCols" v-bind:key="col">
          <div
            :class="{
              'cel-cal':
                row * 7 + col + firsDayCalendarGrid > 0 &&
                row * 7 + col + firsDayCalendarGrid <= lastDayMonth,
              'cell-col-0': col == 0,
              'cel-cal-today': isToday(row, col),
              'cel-cal-old': isOld(row, col),
            }"
            v-if="
              row * 7 + col + firsDayCalendarGrid > 0 &&
              row * 7 + col + firsDayCalendarGrid <= lastDayMonth
            "
            @click="clickOnCell(row, col)"
          >
            <!-- Feasts and Day of the Month -->
            <b-row no-gutters>
              <b-col class="text-left ml-1">
                <span class="badge badge-success">{{
                  getFeast(row, col)
                }}</span>
              </b-col>
              <b-col
                class="text-right pr-1"
                :class="[
                  areThereNotes(row, col) ? 'date-with-notes' : 'text-muted',
                ]"
                >{{ row * 7 + col + firsDayCalendarGrid }}</b-col
              >
            </b-row>

            <!-- Masses -->
            <div
              v-for="Mass in getMassesDay(row, col)"
              v-bind:key="Mass.Day + Mass.StartTime + Mass.Initials"
            >
              <div
                v-if="dbName"
                :class="[
                  Mass.ActInitials == '' ? 'cel-no-mass-info' : 'cel-mass-info',
                ]"
              >
                {{ Mass.ActInitials }}
                {{ Mass.Initials }}
                <span v-if="Mass.Initials==''">No Mass</span>
              </div>
            </div>

            <!-- Activities from MyNetCalendar -->
            <div
              v-for="Act in getMyNetCalActivitiesDay(row, col)"
              v-bind:key="
                Act.Day + Act.StartTime + Act.ActInitials + Act.Initials
              "
            >
              <div class="cal-badges-warning">{{ getMyNetActFormat(Act) }}</div>
            </div>

            <!-- Acitivities -->
            <div v-for="Act in getActivitiesDay(row, col)" v-bind:key="Act.id">
              <div class="cal-badges" variant="danger">
                <span v-if="Act.Icon != '' && Act.Icon != 'arrow-right'">
                  <font-awesome-icon
                    :icon="Act.Icon"
                    size="lg"
                    class="mr-1 icons"
                  />
                </span>
                <span v-html="Act.Act"></span>
                <span v-if="Act.Icon == 'arrow-right'">
                  <font-awesome-icon
                    :icon="Act.Icon"
                    size="lg"
                    class="ml-1 icons"
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            :class="{
              'first-row-cells-blank': row * 7 + col + firsDayCalendarGrid <= 0,
              'last-row-cells-blank':
                row * 7 + col + firsDayCalendarGrid > lastDayMonth,
              'cell-day-0': row * 7 + col + firsDayCalendarGrid == 0,
            }"
          >
            test
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else class="mt-5">
      <font-awesome-icon
        icon="sync-alt"
        spin
        size="3x"
        :style="{ color: 'blue' }"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EditActDay from "@/components/EditActDay-v2.vue";

import NavMonthRow from "@/components/NavMonthRow.vue";

export default {
  data() {
    return {
      calDate: {
        starts: "",
        ends: "",
        date: moment(),
        firstDay: 0,
        lastDay: 30,
        month: 0,
      },
      today: moment(),
      calendarData2: [],
      calendarDataFeasts: [],
      calendarDataMass: [],
      calendarDataMyNetCal: [],
      calendarDataNotes: [],
      calendarDataAct: [],
      calendarClickDay: "",
      calendarRows: [0, 1, 2, 3, 4, 5],
      calendarCols: [0, 1, 2, 3, 4, 5, 6],
      calendarDataLoading: false,
      firsDayCalendarGrid: 0,
      lastDayMonth: 31,
      todayEvents: {
        act: "",
        comments: "",
        coming: "",
        color: "#ffffff",
        date: "",
      },
      count: 0,
    };
  },
  computed: {
    currentMonth() {
      return this.$store.state.currentMonth;
    },
    reloadData() {
      return this.$store.state.reloadData;
    },
    isSmallWindow() {
      return this.$store.state.isSmallWindow;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    centerInitials: function () {
      return this.$store.state.settings.db.centerInitials;
    },
    dbName() {
      return this.$store.state.settings.db.name;
    },
  },
  components: {
    NavMonthRow,
    EditActDay,
  },
  mounted() {
    this.getCalendar();
    this.firsDayCalendarGrid = 0 - moment(this.currentMonth + "-01").day() + 1;
    this.lastDayMonth = moment(this.currentMonth + "-01")
      .endOf("month")
      .date();
  },
  watch: {
    currentMonth: function () {
      this.getCalendar();
      this.firsDayCalendarGrid =
        0 - moment(this.currentMonth + "-01").day() + 1;
      this.lastDayMonth = moment(this.currentMonth + "-01")
        .endOf("month")
        .date();
    },
    reloadData: function () {
      if (this.reloadData) {
        this.getCalendar();
        this.$store.commit("reloadData", false);
      }
    },
  },

  methods: {
    isToday(row, col) {
      let d = row * 7 + col + this.firsDayCalendarGrid;
      let cell = moment(this.calDate.starts).date(d);
      return cell.isSame(this.today, "day");
    },
    isOld(row, col) {
      let d = row * 7 + col + this.firsDayCalendarGrid;
      let cell = moment(this.calDate.starts).date(d);
      return cell.isBefore(this.today, "day");
    },
    areThereNotes(row, col) {
      let d = row * 7 + col + this.firsDayCalendarGrid;
      return this.calendarDataNotes[d];
    },
    getFeast(row, col) {
      let f = this.calendarDataFeasts[
        row * 7 + col + this.firsDayCalendarGrid
      ] || [{ Category: "" }];
      return f[0].Category;
    },
    getMassesDay(row, col) {
      let f = this.calendarDataMass[
        row * 7 + col + this.firsDayCalendarGrid
      ] || [{ ActInitials: "", Initials: "" }];
      return f;
    },
    getMyNetCalActivitiesDay(row, col) {
      let f =
        this.calendarDataMyNetCal[row * 7 + col + this.firsDayCalendarGrid] ||
        [];
      return f;
    },
    getActivitiesDay(row, col) {
      let f =
        this.calendarDataAct[row * 7 + col + this.firsDayCalendarGrid] || [];
      return f;
    },
    getMyNetActFormat(act) {
      let aud = act.AudInitials.replace("n", "").replace("sm", "");
      let st = act.ActInitials + " " + aud;
      st = st.replace("rt", "d/r").replace("med1", "d/r").replace("sr ", "");
      if (act.PlaInitials != this.centerInitials) {
        st += " @ " + act.PlaInitials;
      }
      return st;
    },
    clickOnCell(row, col) {
      let d = row * 7 + col + this.firsDayCalendarGrid;
      let st = moment(this.currentMonth).date(d).format("YYYY-MM-DD");
      this.calendarClickDay = st;
      this.$store.commit("updateDateActEditing", st);
      this.$bvModal.show("modal-act-day"); // calendarClickDay will be passed as props
    },
    getCalendar() {
      this.today = moment();
      this.count++;
      var url = "getCalendarAll-v2.php";
      this.calendarDataLoading = true;
      this.calDate.date = this.currentMonth;
      var d = moment(this.calDate.date);
      this.calDate.month = d.month();
      this.calDate.starts = moment(d).startOf("month").format("YYYY-MM-DD");
      this.calDate.ends = moment(d)
        .startOf("month")
        .add(1, "M")
        .format("YYYY-MM-DD");
      this.calDate.firstDay = moment(d).startOf("month").day();
      this.calDate.lastDay = moment(d).endOf("month").date();

      var data = { starts: this.calDate.starts, ends: this.calDate.ends };

      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          this.calendarDataLoading = false;
          this.calendarDataFeasts = res.data.Feasts;
          this.calendarDataMass = res.data.Mass;
          this.calendarDataMyNetCal = res.data.allPriestEvents;
          this.calendarDataAct = res.data.Act;
          this.calendarDataNotes = res.data.Events;
        },
        () => {
          if (this.count < 10) {
            setTimeout(this.getCalendar, 3000);
            this.$bvToast.toast(`Trying to reconect...`, {
              title: "Connecction lost",
              variant: "info",
              toaster: "b-toaster-top-center",
              autoHideDelay: 2500,
              appendToast: false,
            });
          } else {
            this.$bvToast.toast(
              `Sorry, check your Internet connection and try again...`,
              {
                title: "Connecction lost",
                variant: "info",
                toaster: "b-toaster-top-center",
                noAutoHide: true,
                appendToast: false,
              }
            );
          }
        }
      );
    },
  },
};
</script>