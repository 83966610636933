<style scoped>
</style>

<template>
  <b-row align-v="center" class="text-center">
    <b-col cols="2">
      <b-button style="color: blue; background:none; border:none" @click="monthAdd(-1)">
        <font-awesome-icon icon="caret-left" :style="{ color: 'blue' }" size="3x" />
      </b-button>
    </b-col>
    <b-col cols="8">
      <h5>{{ stMonth }}</h5>
    </b-col>
    <b-col cols="2">
      <b-button style="color: blue; background:none; border:none" @click="monthAdd(1)">
        <font-awesome-icon icon="caret-right" :style="{ color: 'blue' }" size="3x" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    month: {
      get() {
        return this.$store.state.currentMonth;
      },
      set(value) {
        this.$store.commit("updateCurrentMonth", value);
      }
    },
    stMonth() {
      return moment(this.month).format("MMMM YYYY");
    }
  },
  mounted() {},
  methods: {
    monthAdd: function(num) {
      this.month = moment(this.month)
        .add(num, "M")
        .format("YYYY-MM");
    }
  }
};
</script>